import React, { useEffect, useRef } from 'react';
import mapboxgl, {
	Map,
	Marker,
	LngLatBounds,
	FullscreenControl,
} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MapBoxDirectionsType } from './types/mapbox-directions.type';
import { getDirection } from './functions/get-directions';
import { animateRoute } from './functions/animate-route';
import MapPropsInterface from './interfaces/map-props.interace';

if (
	!process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN ||
	typeof process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN !== 'string'
) {
	throw new Error('Mapbox access token is missing');
}

// eslint-disable-next-line import/no-named-as-default-member
mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;

export default function RouteMap({
	from,
	to,
	routeId = 'route',
	withFullscreenControl = false,
	useLocalStorage = true,
}: MapPropsInterface): JSX.Element {
	const mapContainer = useRef(null);
	const map: React.MutableRefObject<Map | null> = useRef(null);

	useEffect(() => {
		if (map.current) return; // initialize map only once

		map.current = new Map({
			container: mapContainer.current as unknown as string | HTMLElement,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: [from.lng, from.lat],
			zoom: 10,
			attributionControl: false,
		});

		if (withFullscreenControl) {
			map.current.addControl(new FullscreenControl());
		}

		void (async (): Promise<void> => {
			if (!map.current) return;

			let coordinates: number[][] | undefined;

			if (useLocalStorage) {
				let coordinatesFromLocalStorage: string | null = null;

				try {
					coordinatesFromLocalStorage = localStorage.getItem(
						`route-${routeId}`
					);
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						console.error(`Cannot get data from localStorage: ${e.stack}`);
					} else {
						console.error(`Cannot get data from localStorage: unknown error`);
					}
				}

				if (coordinatesFromLocalStorage !== null) {
					coordinates = JSON.parse(coordinatesFromLocalStorage) as number[][];
				}
			}

			if (!coordinates) {
				let directions: MapBoxDirectionsType | undefined;
				try {
					directions = await getDirection(from, to);
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						console.error(`Cannot get directions: ${e.stack}`);
					} else {
						console.error(`Cannot get directions: unknown error`);
					}

					return;
				}

				if (directions === undefined || !directions.routes.length) {
					console.error('No routes retrieved');
					return;
				}

				coordinates = directions.routes[0].geometry.coordinates;

				if (useLocalStorage) {
					try {
						localStorage.setItem(
							`route-${routeId}`,
							JSON.stringify(coordinates)
						);
					} catch (e) {
						if (
							e instanceof Error ||
							(typeof e === 'object' && e !== null && 'stack' in e)
						) {
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							console.error(`Cannot set localStorage: ${e.stack}`);
						} else {
							console.error(`Cannot set localStorage: unknown error`);
						}

						return;
					}
				}
			}

			map.current.on('load', (): void => {
				if (!map.current || map.current.getSource(routeId) || !coordinates)
					return;

				try {
					new Marker({ color: 'grey' })
						.setLngLat([from.lng, from.lat])
						.addTo(map.current);
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						console.error(`Cannot set from marker: ${e.stack}`);
					} else {
						console.error(`Cannot set from marker: unknown error`);
					}
				}

				try {
					new Marker({ color: '#0F264A' })
						.setLngLat([to.lng, to.lat])
						.addTo(map.current);
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						console.error(`Cannot set to marker: ${e.stack}`);
					} else {
						console.error(`Cannot set to marker: unknown error`);
					}
				}

				try {
					map.current.addSource(routeId, {
						type: 'geojson',
						data: {
							type: 'Feature',
							properties: {},
							geometry: {
								type: 'LineString',
								coordinates,
							},
						},
					});
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						console.error(`Cannot add source to the map: ${e.stack}`);
					} else {
						console.error(`Cannot add source to the map: unknown error`);
					}

					return;
				}

				try {
					// add a line layer without line-dasharray defined to fill the gaps in the dashed line
					map.current.addLayer({
						type: 'line',
						source: routeId,
						id: 'line-background',
						paint: {
							'line-color': '#BDBDBD',
							'line-width': 6,
							'line-opacity': 0.4,
						},
					});
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						console.error(
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							`Cannot add line background layer to the map: ${e.stack}`
						);
					} else {
						console.error(
							`Cannot add line background layer to the map: unknown error`
						);
					}

					return;
				}

				try {
					// add a line layer with line-dasharray set to the first value in dashArraySequence
					map.current.addLayer({
						type: 'line',
						source: routeId,
						id: 'line-dashed',
						paint: {
							'line-color': '#9E9E9E',
							'line-width': 6,
							'line-dasharray': [0, 4, 3],
						},
					});
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						console.error(
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							`Cannot add line dashed layer to the map: ${e.stack}`
						);
					} else {
						console.error(
							`Cannot add line dashed layer to the map: unknown error`
						);
					}

					return;
				}

				try {
					// start the animation
					animateRoute(map.current, 0);
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						console.error(
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							`Cannot animate route: ${e.stack}`
						);
					} else {
						console.error(`Cannot animate route: unknown error`);
					}
				}

				try {
					const bounds: LngLatBounds = new LngLatBounds(
						coordinates[0] as [number, number],
						coordinates[0] as [number, number]
					);

					for (const coord of coordinates) {
						bounds.extend(coord as [number, number]);
					}

					map.current.fitBounds(bounds, {
						padding: 30,
					});
				} catch (e) {
					if (
						e instanceof Error ||
						(typeof e === 'object' && e !== null && 'stack' in e)
					) {
						console.error(
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							`Cannot fit bounds the map: ${e.stack}`
						);
					} else {
						console.error(`Cannot fit bounds the map: unknown error`);
					}
				}

				return;
			});

			return;
		})();
	});

	return (
		<>
			<div ref={mapContainer} className="map-container h-full" />
		</>
	);
}
