import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import DeliveryMan from '../assets/Delivery man.svg';
import Calendar from '../assets/Date.svg';
import Localisation from '../assets/Localisation.svg';
import Profil from '../assets/profil.svg';
import Door from '../assets/Door.svg';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { missionAtom } from '../Atoms';
import { Loader } from '../components/Loader';
import RouteMap from '../components/RouteMap/RouteMap';

const Recap = () => {
	const { missionId } = useParams();

	const [mission, setMission] = useAtom(missionAtom);
	const [loading, setIsLoading] = useState(false);

	const getMissionData = useCallback(async (missionTransportId: string) => {
		const results = await fetch(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_API_URL}/mission/transporter/${missionTransportId}`,
			{
				headers: {
					'R-Version': 'pro/ios/99.0.0',
				},
			}
		);

		return results.json();
	}, []);

	useEffect(() => {
		if (missionId && !loading) {
			setIsLoading(true);
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
			void getMissionData(missionId).then((results) => setMission(results));
		}
	}, [missionId]);

	if (!mission) {
		return <Loader />;
	}

	return (
		<>
			<Navbar numOrder={mission.id} />
			<div
				className="pt-[45px] bg-blue-900 lg:h-[250px] lg:bg-[image:var(--image-url)]"
				/*@ts-expect-error: var*/
				style={{ '--image-url': `url(${mission.shop.retailer.banner})` }}
			>
				<div className="relative lg:max-w-5xl mx-auto h-full lg:px-4">
					<img
						src={mission.shop.retailer.pic}
						alt=""
						className="absolute left-12 top-1/2 object-contain -translate-y-1/2 w-[85px] h-[85px] lg:w-[120px] lg:h-[120px]"
					/>
					<img
						src={mission.shop.retailer.banner}
						alt=""
						className="w-full lg:hidden"
					/>
				</div>
			</div>
			<div className="p-4 flex flex-col lg:flex-row gap-6 lg:gap-12 lg:max-w-5xl lg:px-4 mx-auto lg:mt-4">
				<div className="lg:w-7/12 lg:order-1">
					<div>
						<span className="font-bold px-2">Mon rendez-vous</span>
						<div className="mt-2 bg-white rounded-md shadow-card py-2 flex flex-col divide-y divide-gray-300">
							<div
								// to={`/tracking/${missionId}/delivery`}
								className="flex flex-row items-center px-4 py-3"
							>
								<img src={DeliveryMan} alt="" />
								<div className="flex flex-col ml-4">
									<span className="text-sm font-medium">Mon livreur</span>
									<span className="text-xs text-gray-400">
										Cogepart
										{/* TODO */}
									</span>
								</div>
								{/*<div className="ml-auto flex flex-row items-center gap-2">*/}
								{/*  <div className="bg-black rounded-full text-white text-xs w-5 h-5 flex items-center justify-center">*/}
								{/*    1/!*  TODO*!/*/}
								{/*  </div>*/}
								{/*  <ChevronRightIcon className="w-5 h-5 text-gray-500" />*/}
								{/*</div>*/}
							</div>
							<div className="flex flex-row items-center px-4 py-3">
								<img src={Calendar} alt="" />
								<div className="flex flex-col ml-4">
									<span className="text-sm font-medium capitalize">
										{new Intl.DateTimeFormat('fr-FR', {
											dateStyle: 'full',
										}).format(new Date(mission.deliveryFrom * 1000))}
									</span>
								</div>
								<div className="flex flex-col ml-auto">
									<span className="text-sm font-medium">
										{`Entre ${new Intl.DateTimeFormat('fr-FR', {
											timeStyle: 'short',
										}).format(
											new Date(mission.deliveryFrom * 1000)
										)}h et ${new Intl.DateTimeFormat('fr-FR', {
											timeStyle: 'short',
										}).format(new Date(mission.deliveryTo * 1000))}h`}
									</span>
								</div>
							</div>
							{/*<div className="flex flex-row items-center px-4 py-5 lg:py-8">*/}
							{/*  <Link*/}
							{/*    to={`/tracking/${missionId}/order`}*/}
							{/*    className="bg-blue-900 w-full text-white text-center rounded-md px-4 py-2 lg:w-3/4 mx-auto"*/}
							{/*  >*/}
							{/*    Suivre ma livraison*/}
							{/*  </Link>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
				<div className="lg:w-5/12 lg:order-0 gap-6 lg:gap-y-6 lg:gap-x-12 flex flex-col">
					<div>
						<span className="font-bold px-2">Infos de livraison</span>
						<div className="mt-2 bg-white rounded-md shadow-card py-2 flex flex-col">
							<div className="w-full h-36 rounded-md px-2">
								<RouteMap
									from={{
										lat: Number.parseFloat(mission.locationStart.lat),
										lng: Number.parseFloat(mission.locationStart.lng),
									}}
									to={{
										lat: Number.parseFloat(mission.locationEnd.lat),
										lng: Number.parseFloat(mission.locationEnd.lng),
									}}
									routeId={mission.id.toString()}
									withFullscreenControl={true}
									useLocalStorage={true}
								/>
							</div>
							<div className="flex flex-col divide-y divide-gray-300 mt-2">
								<div className="flex flex-row items-center px-4 py-3">
									<img src={Localisation} alt="" />
									<div className="flex flex-col ml-4">
										<span className="text-sm font-medium">
											{mission.locationEnd.address},{' '}
											{mission.locationEnd.zipCode}, {mission.locationEnd.city}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center px-4 py-3">
									<img src={Door} alt="" />
									<div className="flex flex-col ml-4">
										<span className="text-sm font-medium">
											Indications livreur
										</span>
										<span className="text-sm font-medium">
											{mission.locationEnd.instructions}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center px-4 py-3">
									<img src={Profil} alt="" />
									<div className="flex flex-col ml-4">
										<span className="text-sm font-medium capitalize">
											{mission.user.firstName} {mission.user.lastName}
										</span>
										<span className="text-sm font-medium">
											{mission.user.phoneNumber}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						{mission.products && mission.products.length > 0 && (
							<>
								<span className="font-bold px-2">Produits</span>
								<div className="mt-2 bg-white rounded-md shadow-card py-2 flex flex-col divide-y divide-gray-300">
									{mission.products.map(({ product, quantity }) => (
										<div
											key={`product-${product.id}`}
											className="flex flex-row items-center px-4 py-5 gap-5"
										>
											<div className="bg-gray-300 text-gray-500 text-xs px-2 py-1 rounded-sm">
												x{quantity}
											</div>
											<img
												src={product.pic}
												className="w-[45px] h-[45px] object-contain"
												alt=""
											/>
											<span className="text-sm font-medium flex-1">
												{product.title}
											</span>
											<div className="bg-gray-300 text-gray-500 w-[45px] h-[36px] flex justify-center items-center ml-auto rounded-sm">
												-
											</div>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default Recap;
