import mapboxgl from 'mapbox-gl';

export function animateRoute(map: mapboxgl.Map, timestamp: number): void {
	// technique based on https://jsfiddle.net/2mws8y3q/
	// an array of valid line-dasharray values, specifying the lengths of the alternating dashes and gaps that form the dash pattern
	const dashArraySequence = [
		[0, 4, 3],
		[0.5, 4, 2.5],
		[1, 4, 2],
		[1.5, 4, 1.5],
		[2, 4, 1],
		[2.5, 4, 0.5],
		[3, 4, 0],
		[0, 0.5, 3, 3.5],
		[0, 1, 3, 3],
		[0, 1.5, 3, 2.5],
		[0, 2, 3, 2],
		[0, 2.5, 3, 1.5],
		[0, 3, 3, 1],
		[0, 3.5, 3, 0.5],
	];

	let step = 0;

	function animateDashArray(tmstmp: number): void {
		// Update line-dasharray using the next value in dashArraySequence. The
		// divisor in the expression `timestamp / 50` controls the animation speed.
		const newStep = parseInt(String((tmstmp / 50) % dashArraySequence.length));

		if (newStep !== step) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			map.setPaintProperty(
				'line-dashed',
				'line-dasharray',
				dashArraySequence[step]
			);
			step = newStep;
		}

		// Request the next frame of the animation.
		requestAnimationFrame(animateDashArray);
	}

	return animateDashArray(timestamp);
}
