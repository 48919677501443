import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
	const error = useRouteError();
	console.error(error);

	return (
		<div id="error-page">
			<h1>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p>
				{/*@ts-expect-error: error can't be determined*/}
				<i>{error?.statusText || error?.message}</i>
			</p>
		</div>
	);
};
export default ErrorPage;
