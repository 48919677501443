import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Recap from './routes/Recap';
import ErrorPage from './ErrorPage';
import { DevTools } from 'jotai-devtools';
import * as dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr'); // use locale

const router = createBrowserRouter([
	{
		path: '/',
		element: <div>Hi !</div>,
	},
	{
		path: '/tracking',
		element: <div>Hi !</div>,
	},
	{
		path: '/tracking/:missionId',
		element: <div>Hi !</div>,
	},
	{
		path: '/tracking/:missionId/recap',
		element: <Recap />,
		errorElement: <ErrorPage />,
	},
	// {
	//   path: "/tracking/:missionId/order",
	//   element: <Order />,
	//   errorElement: <ErrorPage />,
	// },
	// {
	//   path: "/tracking/:missionId/delivery",
	//   element: <Delivery />,
	//   errorElement: <ErrorPage />,
	// },
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
		<DevTools />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
