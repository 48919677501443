import React, { FC } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Logo from '../assets/logo.svg';
import { Link, useParams } from 'react-router-dom';

const Navbar: FC<{ numOrder?: number }> = ({ numOrder }) => {
	const { missionId } = useParams();

	return (
		<div className="w-full z-10 bg-blue-900 fixed">
			<div className="lg:max-w-5xl w-full flex items-center justify-between mx-auto py-3 px-4 h-[45px]">
				{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
				<Link to={`/tracking/${missionId}/recap`}>
					<img src={Logo} alt="" />
				</Link>
				<span className="uppercase text-white">Commande #{numOrder}</span>
				<Bars3Icon className="w-8 h-8 text-gray-500" />
			</div>
		</div>
	);
};

export default Navbar;
