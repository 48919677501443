import React, { FC } from 'react';
import Navbar from './Navbar';

export const Loader: FC = () => {
	return (
		<div>
			<Navbar />
			<div className="pt-[45px] flex items-center justify-center h-screen">
				<div className="p-8 flex flex-col gap-8 justify-center items-center h-full">
					<div className="flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-blue-900 to-bg-gray-300 animate-spin">
						<div className="h-10 w-10 rounded-full bg-gray-300"></div>
					</div>
				</div>
			</div>
		</div>
	);
};
