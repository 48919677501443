import { MapBoxDirectionsType } from '../types/mapbox-directions.type';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mbxDirection from '@mapbox/mapbox-sdk/services/directions';
import { GeolocationType } from '../types/geolocation.type';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
const directionService = mbxDirection({
	accessToken: process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN as string,
});

export async function getDirection(
	from: GeolocationType,
	to: GeolocationType
): Promise<MapBoxDirectionsType | undefined> {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { body }: { body: MapBoxDirectionsType | unknown } =
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		await directionService
			.getDirections({
				profile: 'driving',
				waypoints: [
					{
						coordinates: [from.lng, from.lat],
					},
					{
						coordinates: [to.lng, to.lat],
					},
				],
				overview: 'full',
				geometries: 'geojson',
			})
			.send();

	if (body !== null && typeof body === 'object' && 'routes' in body) {
		return body as MapBoxDirectionsType;
	}

	return undefined;
}
